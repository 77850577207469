import * as React from 'react';
import './Navbar.scss';

export interface IMenuItem {
  name: string;
  link: string;
}

export interface INavbarProps {
  menuLinks: IMenuItem[];
  section: number;
}

export class Navbar extends React.Component<INavbarProps> {
  renderLinks = (className: string, section: number, links: IMenuItem[]) => {
    const navigationClassName = `${className} ${className}--section-${section}`;

    return (
      <ul className={navigationClassName}>
        {links.map(({ name, link }, index) => (
          <li key={index}>
            <a href={link}>{name}</a>
          </li>
        ))}

        <li className="contact">
          <a href="mailto:age.arus@iglu.ee">Küsi Agelt lisa</a>
        </li>
      </ul>
    );
  };

  render() {
    const { menuLinks, section } = this.props;

    return (
      <div id="header">
        <input type="checkbox" id="nav-toggle" />
        <label htmlFor="nav-toggle">
          <span className="white">Toggle navigation</span>
        </label>

        {this.renderLinks('nav', section, menuLinks)}

        <div className="overlay" />

        {this.renderLinks('navigation', section, menuLinks)}
      </div>
    );
  }
}
