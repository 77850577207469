import * as React from 'react';

import Helmet from 'react-helmet';

export interface IHeaderProps {
  title: string;
}

export class Header extends React.Component<IHeaderProps> {
  render() {
    const { title } = this.props;

    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="shortcut icon" type="image/png" href="/assets/favicon.ico" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700,900"
          rel="stylesheet"
          type="text/css"
        />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
      </Helmet>
    );
  }
}
