import * as React from 'react';
import { initialize, pageview } from 'react-ga';
import '../../static/scss/main.scss';
import './IndexPage.scss';

import { graphql } from 'gatsby';
import { Header } from '../components/Header/Header';
import { IMenuItem, Navbar } from '../components/Navbar/Navbar';

// Section 1
import SectionOneMountain from './img/section-1/mountain.svg';
import SectionOneTreesBackLeft from './img/section-1/trees-back-left.svg';
import SectionOneTreesBackRight from './img/section-1/trees-back-right.svg';
import SectionOneTreesFrontLeft from './img/section-1/trees-front-left.svg';
import SectionOneTreesFrontRight from './img/section-1/trees-front-right.svg';
import SectionOneTreesMiddleLeft from './img/section-1/trees-middle-left.svg';
import SectionOneTreesMiddleRight from './img/section-1/trees-middle-right.svg';

// Section 2
import SectionTwoBackground from './img/section-2/bg.svg';
import SectionTwoClouds from './img/section-2/clouds.svg';
import SectionTwoEskimos from './img/section-2/eskimos.svg';
import SectionTwoTreesBack from './img/section-2/trees-back.svg';
import SectionTwoTreesFront from './img/section-2/trees-front.svg';
import SectionTwoTreesMiddle from './img/section-2/trees-middle.svg';

// Section 3
import SectionThreeClouds from './img/section-3/clouds.svg';
import SectionThreeEskimos from './img/section-3/eskimos.svg';
import SectionThreeTreesBack from './img/section-3/trees-back.svg';
import SectionThreeTreesFront from './img/section-3/trees-front.svg';

// Section 4
import SectionFourBackground from './img/section-4/bg.svg';
import SectionFourEskimos from './img/section-4/eskimos.svg';
import SectionFourStars from './img/section-4/stars.svg';
import SectionFourTreesBack from './img/section-4/trees-back.svg';
import SectionFourTreesFront from './img/section-4/trees-front.svg';
import SectionFourTreesMiddle from './img/section-4/trees-middle.svg';

// Section 5
import SectionFiveEskimos from './img/section-5/eskimos.svg';
import SectionFiveTreesBack from './img/section-5/trees-back.svg';
import SectionFiveTreesFront from './img/section-5/trees-front.svg';
import SectionFiveTreesMiddle from './img/section-5/trees-middle.svg';

// Section 6
import SectionSixBackground from './img/section-6/bg.svg';
import SectionSixClouds from './img/section-6/clouds.svg';
import SectionSixEskimos from './img/section-6/eskimo.svg';
import SectionSixTreesBack from './img/section-6/trees-back.svg';
import SectionSixTreesFront from './img/section-6/trees-front.svg';
import SectionSixTreesMiddle from './img/section-6/trees-middle.svg';

interface IDataOption {
  [key: string]: {
    x: number;
    y: number;
    amount: string;
  };
}

interface IDataOptionList {
  [key: string]: IDataOption[];
}

interface IIndexPageProps {
  data: {
    site: {
      siteMetadata: {
        name: string;
        menuLinks: IMenuItem[];
      };
    };
    markdownRemark: {
      html: string;
      frontmatter: {
        title: string;
        section: number;
        layout: number;
      };
    };
  };
}

export default class IndexPage extends React.Component<IIndexPageProps> {
  componentDidMount() {
    initialize('UA-41737689-4');
    pageview(window.location.pathname);

    this.makeSVGMove();
  }

  makeSVGMove() {
    const bgPos = (pos: IDataOption) => ' { transform: translate(' + pos.x + 'px, ' + pos.y + 'px); }';

    let style: Node;

    const rules = (options: IDataOptionList) => {
      const ruleArray = [];
      let group;
      let item;
      for (group in options) {
        if (group) {
          for (item in options[group]) {
            if (item) {
              const data = options[group][item];
              ruleArray.push('#' + group + ' .' + item + bgPos(data));
            }
          }
        }
      }
      return ruleArray;
    };

    const replaceStyle = (styleOptions: IDataOptionList) => {
      const newStyle = document.createElement('style');
      const container = document.head;

      container.appendChild(newStyle);

      rules(styleOptions).forEach((rule) => (newStyle.sheet as CSSStyleSheet).insertRule(rule, 0));

      if (style) {
        container.removeChild(style);
      }
      style = newStyle;
    };

    const opts = {};

    const items = document.querySelectorAll('.parallax');
    items.forEach((i) => {
      const data: any = {};
      [].forEach.call(i.attributes, (attr: { name: string; value: number }) => {
        if (/^data-/.test(attr.name)) {
          const camelCaseName = attr.name.substr(5).replace(/-(.)/g, ($0: string, $1: string) => $1.toUpperCase());
          data[camelCaseName] = attr.value;
        }
      });

      opts[data.group] = opts[data.group] || {};
      opts[data.group][data.name] = opts[data.group][data.name] || {};
      opts[data.group][data.name].x = 0;
      opts[data.group][data.name].y = 0;
      opts[data.group][data.name].amount = data.amount;
    });

    let lastEv: MouseEvent;
    document.body.onmousemove = (ev) => {
      let group;
      let item;
      if (!lastEv) {
        lastEv = ev;
        return;
      }

      const sensitivity = 0.005;
      const dx = lastEv.screenX - ev.screenX;
      const dy = lastEv.screenY - ev.screenY;

      for (group in opts) {
        if (group) {
          for (item in opts[group]) {
            if (item) {
              opts[group][item].x += opts[group][item].amount * dx * sensitivity;
              opts[group][item].y += opts[group][item].amount * dy * sensitivity * 0.5;
            }
          }
        }
      }
      replaceStyle(opts);

      lastEv = ev;
    };
  }

  renderSVGContainer(section: number) {
    const svgContainers: { [key: number]: JSX.Element } = {
      1: (
        <div className="svg-container">
          <img
            data-group="section-1"
            data-name="mountain"
            data-amount="1"
            className="mountain"
            src={SectionOneMountain}
          />
          <div className="left">
            <img
              data-group="section-1"
              data-name="trees-far"
              data-amount="1"
              className="parallax figure trees-far"
              src={SectionFiveTreesBack}
            />
            <img
              data-group="section-1"
              data-name="trees-back"
              data-amount="2"
              className="parallax figure trees-back"
              src={SectionOneTreesBackLeft}
            />
            <img
              data-group="section-1"
              data-name="trees-middle"
              data-amount="3"
              className="parallax figure trees-middle"
              src={SectionOneTreesMiddleLeft}
            />
            <img
              data-group="section-1"
              data-name="trees-front"
              data-amount="4"
              className="parallax figure trees-front"
              src={SectionOneTreesFrontLeft}
            />
          </div>
          <div className="right">
            <img
              data-group="section-1"
              data-name="trees-far"
              data-amount="1"
              className="parallax figure trees-far"
              src={SectionSixTreesBack}
            />
            <img
              data-group="section-1"
              data-name="trees-back"
              data-amount="2"
              className="parallax figure trees-back"
              src={SectionOneTreesBackRight}
            />
            <img
              data-group="section-1"
              data-name="trees-middle"
              data-amount="3"
              className="parallax figure trees-middle"
              src={SectionOneTreesMiddleRight}
            />
            <img
              data-group="section-1"
              data-name="trees-front"
              data-amount="4"
              className="parallax figure trees-front"
              src={SectionOneTreesFrontRight}
            />
          </div>
        </div>
      ),
      2: (
        <div className="svg-container">
          <img
            data-group="section-2"
            data-name="bg"
            data-amount="5"
            className="parallax bg"
            src={SectionTwoBackground}
          />
          <img
            data-group="section-2"
            data-name="trees-back"
            data-amount="8"
            className="parallax figure trees-back"
            src={SectionTwoTreesBack}
          />
          <img
            data-group="section-2"
            data-name="trees-middle"
            data-amount="6"
            className="parallax figure trees-middle"
            src={SectionTwoTreesMiddle}
          />
          <img
            data-group="section-2"
            data-name="trees-front"
            data-amount="4"
            className="parallax figure trees-front"
            src={SectionTwoTreesFront}
          />
          <img
            data-group="section-2"
            data-name="eskimos"
            data-amount="2"
            className="parallax figure eskimos"
            src={SectionTwoEskimos}
          />
          <img
            data-group="section-2"
            data-name="clouds"
            data-amount="1"
            className="parallax clouds"
            src={SectionTwoClouds}
          />
        </div>
      ),
      3: (
        <div className="svg-container">
          <img
            data-group="section-3"
            data-name="bg"
            data-amount="5"
            className="parallax bg"
            src={SectionTwoBackground}
          />
          <img
            data-group="section-3"
            data-name="trees-back"
            data-amount="8"
            className="parallax figure trees-back"
            src={SectionThreeTreesBack}
          />
          <img
            data-group="section-3"
            data-name="trees-front"
            data-amount="4"
            className="parallax figure trees-front"
            src={SectionThreeTreesFront}
          />
          <img
            data-group="section-3"
            data-name="eskimos"
            data-amount="2"
            className="parallax figure eskimos"
            src={SectionThreeEskimos}
          />
          <img
            data-group="section-3"
            data-name="clouds"
            data-amount="1"
            className="parallax clouds"
            src={SectionThreeClouds}
          />
        </div>
      ),
      4: (
        <div className="svg-container">
          <img
            data-group="section-4"
            data-name="bg"
            data-amount="5"
            className="parallax bg"
            src={SectionFourBackground}
          />
          <img
            data-group="section-4"
            data-name="trees-back"
            data-amount="6"
            className="parallax figure trees-back"
            src={SectionFourTreesBack}
          />
          <img
            data-group="section-4"
            data-name="trees-middle"
            data-amount="5"
            className="parallax figure trees-middle"
            src={SectionFourTreesMiddle}
          />
          <img
            data-group="section-4"
            data-name="trees-front"
            data-amount="4"
            className="parallax figure trees-front"
            src={SectionFourTreesFront}
          />
          <img
            data-group="section-4"
            data-name="eskimos"
            data-amount="3"
            className="parallax figure eskimos"
            src={SectionFourEskimos}
          />
          <img
            data-group="section-4"
            data-name="stars"
            data-amount="1"
            className="parallax stars"
            src={SectionFourStars}
          />
        </div>
      ),
      5: (
        <div className="svg-container">
          <img
            data-group="section-5"
            data-name="bg"
            data-amount="5"
            className="parallax bg"
            src={SectionTwoBackground}
          />
          <img
            data-group="section-5"
            data-name="trees-back"
            data-amount="8"
            className="parallax figure trees-back"
            src={SectionFiveTreesBack}
          />
          <img
            data-group="section-5"
            data-name="trees-middle"
            data-amount="6"
            className="parallax figure trees-middle"
            src={SectionFiveTreesMiddle}
          />
          <img
            data-group="section-5"
            data-name="trees-front"
            data-amount="4"
            className="parallax figure trees-front"
            src={SectionFiveTreesFront}
          />
          <img
            data-group="section-5"
            data-name="eskimos"
            data-amount="2"
            className="parallax figure eskimos"
            src={SectionFiveEskimos}
          />
          <img
            data-group="section-5"
            data-name="clouds"
            data-amount="1"
            className="parallax clouds"
            src={SectionThreeClouds}
          />
        </div>
      ),
      6: (
        <div className="svg-container">
          <img
            data-group="section-6"
            data-name="bg"
            data-amount="5"
            className="parallax bg"
            src={SectionSixBackground}
          />
          <img
            data-group="section-6"
            data-name="trees-back"
            data-amount="5"
            className="parallax figure trees-back"
            src={SectionSixTreesBack}
          />
          <img
            data-group="section-6"
            data-name="trees-middle"
            data-amount="4"
            className="parallax figure trees-middle"
            src={SectionSixTreesMiddle}
          />
          <img
            data-group="section-6"
            data-name="trees-front"
            data-amount="3"
            className="parallax figure trees-front"
            src={SectionSixTreesFront}
          />
          <img
            data-group="section-6"
            data-name="eskimos"
            data-amount="3"
            className="parallax figure eskimos"
            src={SectionSixEskimos}
          />
          <img
            data-group="section-6"
            data-name="clouds"
            data-amount="1"
            className="parallax clouds"
            src={SectionSixClouds}
          />
        </div>
      ),
    };

    return svgContainers[section];
  }

  public render() {
    const { data } = this.props;
    const { site, markdownRemark } = data;

    const { siteMetadata } = site;
    const { name, menuLinks } = siteMetadata;

    const { html, frontmatter } = markdownRemark;
    const { title, section, layout } = frontmatter;

    const contentClassName = section === 1 ? 'content' : 'scrollable';

    return (
      <>
        <Header title={name} />
        <Navbar menuLinks={menuLinks} section={section} />
        <section id={`section-${section}`} className={`layout-${layout}`}>
          {this.renderSVGContainer(section)}
          <div className={contentClassName} dangerouslySetInnerHTML={{ __html: html }} />
        </section>
      </>
    );
  }
}

export const query = graphql`
  query($id: String) {
    site {
      siteMetadata {
        name
        menuLinks {
          name
          link
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        section
        layout
      }
    }
  }
`;
